import React, { useEffect, useMemo, useState } from 'react'

import Image from 'next/image'

import { useLegalBannerContext } from '@/contexts/LegalBannerContext'
import facebookImg from '@/img/social/facebook-icon-white.svg'
import instagramImg from '@/img/social/instagram-icon-white.svg'
import linkedinImg from '@/img/social/linkedin-icon-white.svg'
import twitterImg from '@/img/social/twitter-icon-white.svg'
import classNames from 'classnames'
import { Caption } from 'ethos-design-system'
import Cookies from 'js-cookie'
import get from 'lodash.get'
import isEmpty from 'lodash.isempty'

import { useGlobalSettings } from '@/hooks/content/useGlobalSettings'
import { useWreathsFooterUpdate } from '@/hooks/features/useWreathsFooterUpdate'

import {
  LEGAL_BANNER_COOKIE,
  LEGAL_BANNER_COOKIE_VALUE,
} from '../../../constants'
import Markdown, { MARKDOWN_NODE_RESTRICTION_SETS } from '../Markdown'
import styles from './Footer.module.scss'
import {
  DEFAULT_FOOTER_CONTACT_INFO,
  FooterContactInfo,
} from './FooterContactInfo'
import { FooterMenu } from './FooterMenu'
import FootnoteFooter from './FootnoteFooter/FootnoteFooter'

interface FooterProps {
  agentLayout: boolean
  settings: Record<string, any>
}

export interface MenuProps {
  menuHeading: string
  menuItems: Array<{ itemTitle: string; itemUrl: string; linkType: string }>
}

const AGENTS_FOOTER_MENU_KEYS = [
  'agent-footer-menu-1.md',
  'agent-footer-menu-2.md',
  'agent-footer-menu-3.md',
  'agent-footer-menu-4.md',
]

export const CONSUMER_FOOTER_MENU_KEYS = [
  'consumer-footer-menu-1.md',
  'consumer-footer-menu-2.md',
  'consumer-footer-menu-3.md',
  'consumer-footer-menu-4.md',
]

export const GLOBAL_KEY = 'global.md'
export const AGENTS_KEY = 'agent-settings.md'
export const FOOTER_COPYRIGHT_CONTENT_KEY = 'footer-menu-copyright.md'

export const universalAccessProLight = (
  <svg
    data-prefix="fas"
    data-icon="universal-access"
    className={styles.universalAccessIcon}
    aria-label="universal access icon"
    tabIndex={0}
    role="img"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 512 512"
  >
    <circle cx="256" cy="256" r="230" />
    <path d="M256 8C119.033 8 8 119.033 8 256s111.033 248 248 248 248-111.033 248-248S392.967 8 256 8zm0 464c-118.663 0-216-96.055-216-216 0-118.663 96.055-216 216-216 118.663 0 216 96.055 216 216 0 118.663-96.055 216-216 216zm139.701-299.677c2.03 8.601-3.296 17.218-11.896 19.249-31.006 7.321-59.956 13.775-88.689 17.114.535 114.852 13.167 139.988 27.255 176.036 4.02 10.289-1.063 21.888-11.352 25.906-10.296 4.022-21.89-1.069-25.906-11.352-9.319-23.841-18.551-44.565-24.271-87.277h-9.685c-5.728 42.773-14.986 63.515-24.27 87.276-4.023 10.299-15.633 15.369-25.906 11.353-10.289-4.019-15.371-15.617-11.353-25.906 14.103-36.083 26.72-61.235 27.255-176.036-28.734-3.339-57.683-9.793-88.689-17.114-8.6-2.031-13.926-10.648-11.895-19.249 2.03-8.6 10.647-13.926 19.249-11.896 105.222 24.845 135.48 24.893 240.904 0 8.599-2.022 17.218 3.297 19.249 11.896zm-177.895-42.517c0-21.094 17.1-38.193 38.194-38.193s38.194 17.1 38.194 38.193S277.094 168 256 168s-38.194-17.1-38.194-38.194z" />
  </svg>
)

const Footer: React.FC<FooterProps> = ({ agentLayout, settings }) => {
  const [isDisclaimerOpen, setIsDisclaimerOpen] = useState(false)
  const { disclaimerHeight } = useLegalBannerContext()

  const { isTreatment } = useWreathsFooterUpdate()

  const agentFooterContent = useGlobalSettings(
    AGENTS_FOOTER_MENU_KEYS,
    settings
  )
  const consumerFooterContent = useGlobalSettings(
    CONSUMER_FOOTER_MENU_KEYS,
    settings
  )
  const footerCopyrightContent = useGlobalSettings(
    FOOTER_COPYRIGHT_CONTENT_KEY,
    settings
  )
  const globalSocial = useGlobalSettings(GLOBAL_KEY, settings)
  const agentsSocial = useGlobalSettings(AGENTS_KEY, settings)

  useEffect(() => {
    const legalBannerCookie = Cookies.get(LEGAL_BANNER_COOKIE)
    if (legalBannerCookie !== LEGAL_BANNER_COOKIE_VALUE) {
      setIsDisclaimerOpen(true)
    }
  }, [setIsDisclaimerOpen])

  const footerContent = agentLayout ? agentFooterContent : consumerFooterContent

  const colophonText = footerCopyrightContent.copyrightText

  const trimmedMenus: Array<MenuProps> = footerContent.filter(
    (menu: MenuProps) => {
      if (menu.menuItems && !isEmpty(menu.menuItems)) {
        return menu
      }
    }
  )

  const mainMenuClasses = [styles.mainMenu]
  const menusClasses = [styles.menus]
  const MAIN_MENU_COLUMNS = trimmedMenus.length

  if (MAIN_MENU_COLUMNS === 4) {
    mainMenuClasses.push(styles.fourCol)
  } else if (MAIN_MENU_COLUMNS === 3) {
    mainMenuClasses.push(styles.threeCol)
  }

  if (isDisclaimerOpen && !isTreatment) {
    menusClasses.push(styles.cookie)
  }

  if (isTreatment) {
    menusClasses.push(styles.treatment)
  }

  const renderSocialIcons = useMemo(() => {
    const socialIcons = {
      facebookURL: agentLayout
        ? agentsSocial.agentFacebookURL
        : globalSocial.facebookURL,
      twitterURL: agentLayout
        ? agentsSocial.agentTwitterURL
        : globalSocial.twitterURL,
      instagramURL: agentLayout
        ? agentsSocial.agentInstagramURL
        : globalSocial.instagramURL,
      linkedinURL: agentLayout
        ? agentsSocial.agentLinkedinURL
        : globalSocial.linkedinURL,
    }

    const { facebookURL, twitterURL, instagramURL, linkedinURL } = socialIcons

    return (
      <div className={styles.icons}>
        {facebookURL && (
          <a href={facebookURL}>
            <Image src={facebookImg} alt="Facebook Icon" />
          </a>
        )}
        {twitterURL && (
          <a href={twitterURL}>
            <Image src={twitterImg} alt="Twitter Icon" />
          </a>
        )}
        {instagramURL && (
          <a href={instagramURL}>
            <Image src={instagramImg} alt="Instagram Icon" />
          </a>
        )}
        {linkedinURL && (
          <a href={linkedinURL}>
            <Image src={linkedinImg} alt="LinkedIn Icon" />
          </a>
        )}
        <div className={styles.flexStretch} />
      </div>
    )
  }, [agentLayout, agentsSocial, globalSocial])

  const renderContactInfo = useMemo(() => {
    const phoneNumber = agentLayout
      ? get(
          agentsSocial,
          'agentPhoneNumber',
          DEFAULT_FOOTER_CONTACT_INFO.PARTNER_PHONE_NUMBER.DISPLAY
        )
      : DEFAULT_FOOTER_CONTACT_INFO.PHONE_NUMBER.DISPLAY

    const phoneLink = agentLayout
      ? get(
          agentsSocial,
          'phoneLink',
          DEFAULT_FOOTER_CONTACT_INFO.PARTNER_PHONE_NUMBER.LINK
        )
      : DEFAULT_FOOTER_CONTACT_INFO.PHONE_NUMBER.LINK

    const emailLink = agentLayout
      ? get(agentsSocial, 'emailLink', DEFAULT_FOOTER_CONTACT_INFO.EMAIL_LINK)
      : DEFAULT_FOOTER_CONTACT_INFO.EMAIL_LINK
    return (
      <FooterContactInfo
        phoneNumber={phoneNumber}
        phoneLink={phoneLink}
        displayEmail={true}
        emailLink={emailLink}
      />
    )
  }, [agentLayout, agentsSocial])

  return (
    <footer className={styles.footer}>
      {!isTreatment && <FootnoteFooter />}
      <div
        className={classNames(styles.container, {
          [styles.treatmentContainer]: isTreatment,
        })}
      >
        <div className={menusClasses.join(' ')}>
          <div className={styles.contact}>
            {renderContactInfo}
            {renderSocialIcons}
          </div>
          <div className={mainMenuClasses.join(' ')}>
            {trimmedMenus.map((menu: MenuProps, i: number) => (
              <FooterMenu menu={menu} key={`menu_${i}`} />
            ))}
          </div>
          <div className={styles.colophon}>
            <Caption.Regular400>
              <Markdown
                input={colophonText}
                allowedMarkdownTypes={MARKDOWN_NODE_RESTRICTION_SETS.LINK_ONLY}
              />
            </Caption.Regular400>
            <div
              role="button"
              aria-label="universal access menu trigger"
              className={styles.universalAccessContainer}
              id="universal-access-menu-trigger"
            >
              {universalAccessProLight}
            </div>
          </div>
        </div>
      </div>
      {isTreatment && (
        <div style={{ paddingBottom: disclaimerHeight }}>
          <FootnoteFooter />
        </div>
      )}
    </footer>
  )
}

export default Footer
